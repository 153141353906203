import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import styled from 'styled-components'

import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import { DAEMON } from 'utils/constants'
import reducer from './reducers'
import sagas from './sagas'

import { doPostMessage, doPostMessageError } from './actions'
import { selectDoSlackAuth, selectQueryStringCode } from './selectors'

import BarLoadingAnimation from 'components/BarLoadingAnimation'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'

import messages from './messages'

const ContentContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  text-align: center;
  padding-top: 90px;
`

const MessageContainer = styled.h3`
  margin-top: 10px;
  margin-bottom: 10px;
`

function BigStatusMessage(props) {
  return (
    <ContentContainer>
      <MessageContainer>
        <DynamicFormattedMessage {...props} />
      </MessageContainer>
    </ContentContainer>
  )
}

export class SlackApplicationAuthorization extends React.Component {
  constructor(props, context) {
    super(props, context)
    if (props.code) {
      this.props.doPostMessage({ code: props.code })
    } else {
      this.props.doPostMessageError()
    }
  }

  render() {
    if (this.props.result.get('loading')) {
      return <BarLoadingAnimation />
    }

    if (this.props.result.get('error')) {
      return <BigStatusMessage {...messages.error} />
    }

    if (!this.props.code) {
      // eslint-disable-next-line no-console
      console.error(`${this.displayName}: invalid code ${this.props.code}`)
      return <BigStatusMessage {...messages.error} />
    }

    return <BigStatusMessage {...messages.success} />
  }
}

SlackApplicationAuthorization.propTypes = {
  // ~ bound action creators ~
  doPostMessage: PropTypes.func.isRequired,
  doPostMessageError: PropTypes.func.isRequired,

  // ~ Props from redux ~
  code: PropTypes.string.isRequired,
  result: ImmutablePropTypes.contains({
    loading: PropTypes.bool,
    error: PropTypes.bool,
  }),
}

const mapStateToProps = createStructuredSelector({
  code: selectQueryStringCode,
  result: selectDoSlackAuth,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ doPostMessage, doPostMessageError }, dispatch)
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({ key: 'doSlackAuthorization', reducer })
const withSaga = injectSaga({
  key: 'doSlackAuthorization',
  saga: sagas,
  mode: DAEMON,
})

export default compose(
  withConnect,
  withReducer,
  withSaga
)(SlackApplicationAuthorization)

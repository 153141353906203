import { createAction } from 'redux-actions'

import {
  DO_POST_MESSAGE,
  DO_POST_MESSAGE_SUCCESS,
  DO_POST_MESSAGE_ERROR,
} from './constants'

export const doPostMessage = createAction(DO_POST_MESSAGE)
export const doPostMessageSuccess = createAction(DO_POST_MESSAGE_SUCCESS)
export const doPostMessageError = createAction(DO_POST_MESSAGE_ERROR)
import { all, put, takeLatest } from 'redux-saga/effects'

import { doPostMessageError, doPostMessageSuccess } from './actions'
import { DO_POST_MESSAGE } from './constants'

function* doPostMessage(action) {
  const { code } = action.payload
  try {
    window.opener.postMessage({ slack: true, code }, '*')
    yield put(doPostMessageSuccess())
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`There was an error posting message to Sonrai.`, e)
    yield put(doPostMessageError())
  }
}

function* securityCenterSaga() {
  yield all([takeLatest(DO_POST_MESSAGE, doPostMessage)])
}

export default securityCenterSaga

import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import {
  DO_POST_MESSAGE,
  DO_POST_MESSAGE_ERROR,
  DO_POST_MESSAGE_SUCCESS,
} from './constants'

export const initialState = fromJS({
  doSlackAuth: {
    loading: true,
    error: false,
  },
})

export default handleActions(
  {
    [DO_POST_MESSAGE]: state =>
      state
        .setIn(['doSlackAuth', 'loading'], true)
        .setIn(['doSlackAuth', 'error'], false),
    [DO_POST_MESSAGE_ERROR]: state =>
      state
        .setIn(['doSlackAuth', 'loading'], false)
        .setIn(['doSlackAuth', 'error'], true),
    [DO_POST_MESSAGE_SUCCESS]: state =>
      state
        .setIn(['doSlackAuth', 'loading'], false)
        .setIn(['doSlackAuth', 'error'], false),
  },
  initialState
)

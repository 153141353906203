import { defineMessages } from 'react-intl'

export default defineMessages({
  error: {
    id: 'aoo.containers.slackApplicationAuthorization.error',
    defaultMessage: 'There was an error authorizing the Slack application',
  },

  success: {
    id: 'aoo.containers.slackApplicationAuthorization.error',
    defaultMessage: 'Slack Application successfully authorized.',
  },
})

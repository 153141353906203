import { createSelector } from 'reselect'
import _ from 'lodash'
import qs from 'query-string'
import { initialState } from './reducers'

export const selectQueryStringCode = (state, props) => {
  return qs.parse(_.get(props, ['location', 'search'], '')).code
}

export const selectDomain = state => state.get('doSlackAuthorization') || initialState

export const selectDoSlackAuth = createSelector(selectDomain, state =>
  state.get('doSlackAuth')
)
